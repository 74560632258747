import React from "react";
import styles from "./Newsletter.module.css";

const NewsletterSection = () => {
  return (
    <section className={styles.newsletterSection}>
      <div className={styles.container}>
        <h3>Sign up for our Newsletter</h3>
        <p>
          Get the latest updates, news, and special offers delivered straight to
          your inbox.
        </p>
        <form>
          <input type="email" placeholder="Enter your email" />
          <button type="submit">Subscribe</button>
        </form>
      </div>
    </section>
  );
};

export default NewsletterSection;
