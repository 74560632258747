import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "../screens/About";
import Home from "../screens/Home";
import { ABOUT_PATH, HOME_PATH } from "../constants/routePaths";

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path={HOME_PATH} element={<Home />} />
      <Route path={ABOUT_PATH} element={<About />} />
    </Routes>
  );
};

export default AppRoutes;
