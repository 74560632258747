import { Link, Navbar, Text } from "@nextui-org/react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AcmeLogo } from "./AcmeLogo.js";
import styles from "./NavbarApp.module.css";
import { menuItems } from "../../constants/menuItems.js";

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  // Update activeLink state based on the current location
  React.useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Navbar shouldHideOnScroll isBordered variant="sticky">
      <Navbar.Brand
        onClick={() => {
          navigate("/");
        }}
        className={styles.onhover}
      >
        <AcmeLogo />
        <Text b color="inherit" hideIn="xs">
          Shamshersons
        </Text>
      </Navbar.Brand>

      <Navbar.Content>
        <Navbar.Toggle aria-label="toggle navigation" showIn="xs" />
        <Navbar.Content enableCursorHighlight hideIn="xs" variant="underline">
          {menuItems.map((item, index) => (
            <Navbar.Link
              key={index}
              isActive={activeLink === item.path}
              onClick={() => handleNavigation(item.path)}
            >
              {item.name}
            </Navbar.Link>
          ))}
        </Navbar.Content>
      </Navbar.Content>

      <Navbar.Collapse>
        {menuItems.map((item, index) => (
          <Navbar.CollapseItem key={index}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              onClick={() => handleNavigation(item.path)}
            >
              {item.name}
            </Link>
          </Navbar.CollapseItem>
        ))}
      </Navbar.Collapse>
    </Navbar>
  );
}
