import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./components/footer/Footer";
import { Loader } from "./components/loader";
import NavbarApp from "./components/navbar/NavbarApp";
import NewsletterSection from "./components/newsletter/Newsletter";
import { HOME_PATH } from "./constants/routePaths";
import AppRoutes from "./routes/AppRoutes";

const App = () => {
  let [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {loading && location.pathname === HOME_PATH ? (
        <Loader loading={loading} />
      ) : (
        <>
          <NavbarApp />
          <AppRoutes />
          <NewsletterSection />
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
