import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.footerContent}>
          <div className={styles.footerSection}>
            <h3>About Us</h3>
            <p>
              Trusted for a century. Your go-to piano rental and moving experts,
              delivering excellence and care since 1923.
            </p>
          </div>
          <div className={styles.footerSection}>
            <h3>Contact Us</h3>
            <p>
              100, Ram Nagar Market, Qutab Rd, Paharganj, New Delhi, Delhi
              110055, India
              <br />
              Email: shamshersons@yahoo.co.in
              <br />
              Phone: +1 123 456 7890
            </p>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <p>&copy; {currentYear} Shamshersons. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
