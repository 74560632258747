import {
  ABOUT_PATH,
  COMPANY_PATH,
  CUSTOMERS_PATH,
  HOME_PATH,
  PRICING_PATH,
} from "./routePaths";

export const menuItems = [
  { name: "Home", path: HOME_PATH },
  { name: "About", path: ABOUT_PATH },
  { name: "Customers", path: CUSTOMERS_PATH },
  { name: "Pricing", path: PRICING_PATH },
  { name: "Company", path: COMPANY_PATH },
];
