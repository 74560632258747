const Home = () => {
  return (
    <>
      <img
        src="https://source.unsplash.com/random/2000x800/?piano"
        alt="Random Piano Image"
      />
      <h2>this is home page</h2>
    </>
  );
};

export default Home;
