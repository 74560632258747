import PropagateLoader from "react-spinners/PropagateLoader";
import styled from "styled-components";

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Loader = (loading) => {
  return (
    <CenterDiv>
      <PropagateLoader
        color={"blue"}
        loading={loading}
        aria-label="Loading Spinner"
      />
    </CenterDiv>
  );
};
